import axios from "axios";
import React, { useContext, useState } from 'react';
import { BsFillSendFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import AuthContext from "../context/AuthProvider";
import SectionHead from "./SectionHead";

const Form = (props) => {
    const {domain} = useContext(AuthContext)
  const host = "https://nexon.eazotel.com/eazotel/addcontacts";

  const [formData, setFormData] = useState({
    username: "",
    phone: "",
    email: "",
    message: "",
  });

  const handleChnage = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const preparedData = {
        Domain: domain,
        email: formData.email,
        Name: formData.username,
        Contact: formData.phone,
        Description: formData.message,
      };
      const response = await axios.post(host, preparedData);

      // console.log(response.data);
    } catch (error) {
      console.log("Error: ", error);
    }
    // sssetFormData(formData);

    setFormData({
      username: "",
      phone: "",
      email: "",
      message: "",
    });
  };
  return (
    <div
      id="contact"
      className="!mt-20 maxwidth px-5 max-md:!mt-10 max-sm:!mt-10"
    >
      <SectionHead
        head={props.sectionData?.Title}
        subHead={props.sectionData?.Description}
      />
      <div className="mt-10 w-full max-md:max-w-full">
        <div className="flex gap-5 max-md:gap-5 max-sm:flex-col">
          <div className="flex flex-col w-[64%] max-md:w-[50%] max-sm:w-[100%] max-md:ml-0 ">
            <div className="flex flex-col grow items-start max-md:mt-5 max-md:max-w-full">
              <div className="card-heading capitalize max-md:max-w-full">
                Contact Details
              </div>
              <div className="flex gap-3 mt-6 items-start">
                <img
                  loading="lazy"
                  alt="phone"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/2f2d8b1b90c8f2520d8ca76b3f2a28db92e7b9ea809561aca983d2ee1ae7e6bf?"
                  className="shrink-0 w-6 aspect-square"
                />
                <div className="paragraph">{props.phone}</div>
              </div>
              <div className="flex gap-3 mt-4 items-start">
                <img
                  loading="lazy"
                  alt="email"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/0231b7a20e6e529b5f7c9245d074719afe8806f960af67361074cfd1c208e0fc?"
                  className="shrink-0 w-6 aspect-square"
                />
                <div className="paragraph">{props.email}</div>
              </div>
              <div className="flex items-start gap-2.5 mt-4">
                <img
                  loading="lazy"
                  alt="address"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/01a3ebc7fe0aec3ce3a7c2e95724ca9e1bd0b4e16c238f710ea00d23dcb5c8fd?"
                  className="shrink-0 w-6 aspect-square"
                />
                <div className="paragraph">{props.address}</div>
              </div>
              <div className="max-sm:hidden w-full mt-10 max-md:mt-5">
                <iframe
                  src={props.location}
                  width="100%"
                  // height="317"
                  loading="lazy"
                  title="location"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="h-[317px] max-md:h-[298px]"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col  w-[36%]  max-md:ml-0 max-md:w-[50%] max-sm:w-[100%]">
            <div className="flex flex-col grow justify-center max-md:justify-start text-base max-md:mt-5">
              <form
                onSubmit={handleSubmit}
                className="flex flex-col pt-7 pb-3 max-md:pt-5 w-full bg-[#FAF8F5]"
              >
                <div className="card-heading capitalize px-6 max-md:px-2.5 max-md:mx-0 ">
                  Get in Touch!
                </div>
                <div className="flex items-center gap-3 self-start mt-8 px-6 max-md:px-2.5 w-100">
                  <FaUser size={18} className="text-[#978667]" />
                  <input
                    type="text"
                    name="username"
                    placeholder="Your name"
                    value={formData.username}
                    onChange={handleChnage}
                    required
                    className="paragraph outline-none bg-transparent w-full"
                  />
                </div>
                <div className="shrink-0 mx-6 mt-6 max-w-full h-px border border-solid bg-[#978667] border-[#978667] max-md:mx-0" />
                <div className="flex items-center gap-3 self-start mt-8  px-6 max-md:px-2.5 whitespace-nowrap  w-full">
                  <FaPhone size={18} className="text-[#978667]" />
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone Number"
                    value={formData.phone}
                    onChange={handleChnage}
                    required
                    className="paragraph outline-none bg-transparent w-full"
                  />
                </div>
                <div className="shrink-0 mx-6 mt-6 max-w-full h-px border border-solid bg-[#978667] border-[#978667]  max-md:mx-0" />
                <div className="flex items-center gap-3 self-start mt-8  px-6 max-md:px-2.5 whitespace-nowrap  w-full">
                  <MdEmail size={18} className="text-[#978667]" />
                  <input
                    type="email"
                    name="email"
                    placeholder="Your Email"
                    value={formData.email}
                    onChange={handleChnage}
                    required
                    className="paragraph outline-none bg-transparent w-full"
                  />
                </div>
                <div className="shrink-0 mx-6 mt-6 max-w-full h-px border border-solid bg-[#978667] border-[#978667]  max-md:mx-0" />
                <div className="flex items-center gap-3 self-start mt-8  px-6 max-md:px-2.5 whitespace-nowrap  w-full">
                  <BsFillSendFill size={18} className="text-[#978667]" />
                  <input
                    type="text"
                    name="message"
                    placeholder="Your Message"
                    value={formData.message}
                    onChange={handleChnage}
                    required
                    className="paragraph outline-none bg-transparent w-full"
                  />
                </div>
                <div className="shrink-0 mx-6 mt-6 max-w-full h-px border border-solid bg-[#978667] border-[#978667]  max-md:mx-0" />
                <button
                  type="submit"
                  className=" justify-center items-center px-16 py-6 mt-11 font-semibold tracking-wide text-white whitespace-nowrap bg-[#978667] leading-[150%] max-md:px-5 max-md:mt-10"
                >
                  Submit
                </button>
              </form>
            </div>
          </div>

          <div className="sm:hidden md:hidden max-sm:block w-full mt-10 max-md:mt-5">
            <iframe
              src={props.location}
              width="100%"
              // height="317"
              loading="lazy"
              title="location"
              referrerPolicy="no-referrer-when-downgrade"
              className="h-[317px] max-md:h-[298px] max-sm:h-[100%]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
