import React, { useState } from "react";
import Logo from "../assets/Vector (8).svg";
import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { IoClose } from "react-icons/io5";

const Navbar = (props) => {
  const [openNav, setOpenNav] = useState(false);

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div>
      <div className="maxwidth flex justify-between items-center py-3 px-5 bg-white">
        <div className="flex gap-10 justify-between max-md:flex-wrap max-h-[80px]">
          <img
            loading="lazy"
            src={props.logo}
            alt="logo"
            className="aspect-square h-[80px] w-[80px]"
          />

          <div className="lg:flex hidden gap-10 max-md:gap-5 flex-grow justify-between my-auto text-[#333333] tracking-wide leading-6 uppercase max-md:flex-wrap">
            <Link>Home</Link>
            <Link onClick={() => scrollToSection('about')}>ABOUT US</Link>
            <Link onClick={() => scrollToSection('rooms')} >Rooms</Link>
            <Link onClick={() => scrollToSection('facilities')} >FACILITIES</Link>
            <Link onClick={() => scrollToSection('gallery')} >Gallery</Link>
            <Link onClick={() => scrollToSection('contact')} >CONTACT US</Link>
          </div>
        </div>

        <div className=" hidden lg:flex gap-3">
          <Link to={props.facebook} target="_blank">
            <img
              alt="facebook"
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c2e6dcd65112f296e9148a79b5eb5aabd79da835c3b2e07125c338441d446bb?"
              className="shrink-0 aspect-square w-[42px]"
            />
          </Link>
          <Link to={props.instagram} target="_blank">
            <img
              loading="lazy"
              alt="instagram"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f718eb943286fe95ea607976acecc24a6e58f62af98cd0c2bfa8fc5b639c323f?"
              className="shrink-0 aspect-square w-[42px]"
            />
          </Link>
          <Link to={props.tripadvisors} target="_blank">
            <img
              loading="lazy"
              alt="tripAdvisor"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/f60aed2e3615f3f5f4601f43ee316a21dc47674c7c3ff670560198ed706ab590?"
              className="shrink-0 aspect-square w-[42px]"
            />
          </Link>
        </div>

        <div className="lg:hidden" onClick={() => setOpenNav(!openNav)}>
          <AiOutlineMenu size={25} />
        </div>
      </div>
      <div className="sm:hidden">
        <MobileNav openNav={openNav} setOpenNav={setOpenNav} facebook={props.facebook} instagram={props.instagram} tripadvisors={props.tripadvisors} />
      </div>
    </div>
  );
};

const MobileNav = ({ openNav, setOpenNav, facebook, instagram, tripadvisors }) => {
  const scrollToSection = (id) => {
    setOpenNav(!openNav)
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div
      className="flex flex-col w-[300px] max-sm:w-[80%] h-screen px-5 fixed top-0 bg-white z-10 "
      style={{
        right: openNav ? "0" : "-100%",
        transition: "all 0.3s linear",
      }}
    >
      <div
        className="flex justify-end mt-2 text-end text-[1.7rem] font-semibold"
        onClick={() => setOpenNav(false)}
      >
        <IoClose color="#333333" />
      </div>
      <div>
        <div className="flex flex-col gap-5 justify-between my-auto text-[#333333] tracking-wide leading-6 uppercase ">
          <Link>Home</Link>
          <Link onClick={() => scrollToSection('about')}>ABOUT US</Link>
          <Link onClick={() => scrollToSection('rooms')} >Rooms</Link>
          <Link onClick={() => scrollToSection('facilities')} >FACILITIES</Link>
          <Link onClick={() => scrollToSection('gallery')} >Gallery</Link>
          <Link onClick={() => scrollToSection('contact')} >CONTACT US</Link>
        </div>

        <div className="flex gap-5 justify-between my-auto max-md:flex-wrap max-md:max-w-full mt-4">
          <div className="flex gap-3">
            <Link onClick={() => setOpenNav(!openNav)} to={facebook} target="_blank">
              <img
                alt="facebook"
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/4c2e6dcd65112f296e9148a79b5eb5aabd79da835c3b2e07125c338441d446bb?"
                className="shrink-0 aspect-square w-[42px]"
              />
            </Link>
            <Link onClick={() => setOpenNav(!openNav)} to={instagram} target="_blank">
              <img
                loading="lazy"
                alt="instagram"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f718eb943286fe95ea607976acecc24a6e58f62af98cd0c2bfa8fc5b639c323f?"
                className="shrink-0 aspect-square w-[42px]"
              />
            </Link>
            <Link onClick={() => setOpenNav(!openNav)} to={tripadvisors} target="_blank">
              <img
                loading="lazy"
                alt="tripAdvisor"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/f60aed2e3615f3f5f4601f43ee316a21dc47674c7c3ff670560198ed706ab590?"
                className="shrink-0 aspect-square w-[42px]"
              />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
