import React from "react";
import { Helmet } from "react-helmet";

function Meta(props) {
  // console.log(props);
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.Data.title}</title>
      <meta name="description" content={props.Data.description} />
      <link rel="icon" href={props.Data.favicon} />
    </Helmet>
  );
}

export default Meta;
