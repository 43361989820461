import React from "react";

const SectionHead = ({ head, subHead }) => {
  return (
    <>
      <div className="section-heading">{head}</div>
      {subHead && <p className="text-center para mt-2">{subHead}</p>}
    </>
  );
};

export default SectionHead;
