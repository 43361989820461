import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {

  const [linktohit,setlinktohit] = useState("https://nexon.eazotel.com")
  const [domain,setdomain] = useState('ahistays')
  const [HotelName,setHotelName] = useState("")
  const [websiteData, setWebsiteData] = useState({});
  const [loading, setLoading] = useState(true);
  const [roomsData, setRoomsData] = useState([]);
  const [BookingEngineData,setBookingEngineData] = useState()

  const fetchWebsiteData = async (dom) => {
    try {
      setLoading(true);
      const response = await fetch(`https://nexon.eazotel.com/cms/get/website/${dom}`);
      if (!response.ok) {
        throw new Error("Failed to fetch website data");
      }
      const data = await response.json();
      // console.log(data);
      setWebsiteData(data.WebsiteData);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const fetchRooms = async () => {
    const response = await fetch(`https://nexon.eazotel.com/room/get/room/website/${domain}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, /",
        "Content-Type": "application/json",
      },
    });

    const json = await response.json();
    // console.log(json);

    setRoomsData([...json.data]);
  };

  const today = new Date().toISOString().split('T')[0];

  // Get tomorrow's date by adding 1 day to the current date
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);

  const tomorrow = tomorrowDate.toISOString().split('T')[0];
 

  const [selectedDate, setSelectedDate] = useState(today);
  const [nextselectedDate,setnextselectedDate] = useState(tomorrow);
  const [Nights,setNights] = useState(0)
  const [minAdult,setminAdult] = useState(1)
  const [minkids,setminkids] = useState(0)
  const [Adult,setAdult] = useState(1)
  const [kids,setkids] = useState(0)

  //Rooms For Bookings
    const [BookingEngineRooms , setBookingEngineRooms] = useState([])
    const [RateChange,setratesChange] = useState()
    const [Packages,setPackages] = useState([])
    const [mealplan, setMealPlan] = useState([]);
    const [currency,setcurrency] = useState("INR")
    const [RoomCategoryCombination, setRoomCategoryCombination] = useState({
      "DELUX": "-",
      "SUPERDELUX": "-",
      "SUITE": "-",
      "PREMIUM": "-",
      "PremiereRetreat": "-",
      "EliteSuite": "-",
      "GrandDeluxe": "-",
      "ImperialSuite": "-",
      "SupremeRetreat": "-",
      "RoyalDeluxe": "-",
      "PrestigeSuite": "-",
      "ExclusiveRetreat": "-"


    })
    const [Available, setAvailable] = useState({
      "DELUX": 0,
      "PREMIUM": 0,
      "SUITE": 0,
      "SUPERDELUX": 0,
      "PremiereRetreat": 0,
      "EliteSuite": 0,
      "GrandDeluxe": 0,
      "ImperialSuite": 0,
      "SupremeRetreat": 0,
      "RoyalDeluxe": 0,
      "PrestigeSuite": 0,
      "ExclusiveRetreat": 0
  })

    const [RoomNameAvailable, setRoomNameAvailable] = useState({
        "DELUX": "DELUX",
        "PREMIUM": "PREMIUM",
        "SUITE": "SUITE",
        "SUPER DELUX": "SUPERDELUX",
        "Premiere Retreat": "PremiereRetreat",
        "Elite Suite": "EliteSuite",
        "Grand Deluxe": "GrandDeluxe",
        "Imperial Suite": "ImperialSuite",
        "Supreme Retreat": "SupremeRetreat",
        "Royal Deluxe": "RoyalDeluxe",
        "Prestige Suite": "PrestigeSuite",
        "Exclusive Retreat": "ExclusiveRetreat"
    })
    let [maxAdult, setmaxAdult] = useState(0)
    const [Delux, setDelux] = useState(0)
    const [SuperDelux, setSuperDelux] = useState(0)
    const [Suite, setSuite] = useState(0)
    const [Premium, setPremium] = useState(0)
    const [PremiereRetreat, setPremiereRetreat] = useState(0)
    const [EliteSuite, setEliteSuite] = useState(0)
    const [GrandDeluxe, setGrandDeluxe] = useState(0)
    const [ImperialSuite, setImperialSuite] = useState(0)
    const [SupremeRetreat, setSupremeRetreat] = useState(0)
    const [RoyalDeluxe, setRoyalDeluxe] = useState(0)
    const [PrestigeSuite, setPrestigeSuite] = useState(0)
    const [ExclusiveRetreat, setExclusiveRetreat] = useState(0)


    const [DeluxAdult, setDeluxAdult] = useState(0)
    const [SuperDeluxAdult, setSuperDeluxAdult] = useState(0)
    const [SuiteAdult, setSuiteAdult] = useState(0)
    const [PremiumAdult, setPremiumAdult] = useState(0)
    const [PremiereRetreatAdult, setPremiereRetreatAdult] = useState(0)
    const [EliteSuiteAdult, setEliteSuiteAdult] = useState(0)
    const [GrandDeluxeAdult, setGrandDeluxeAdult] = useState(0)
    const [ImperialSuiteAdult, setImperialSuiteAdult] = useState(0)
    const [SupremeRetreatAdult, setSupremeRetreatAdult] = useState(0)
    const [RoyalDeluxeAdult, setRoyalDeluxeAdult] = useState(0)
    const [PrestigeSuiteAdult, setPrestigeSuiteAdult] = useState(0)
    const [ExclusiveRetreatAdult, setExclusiveRetreatAdult] = useState(0)

    const [GatewayConnected, setGatewayConnected] = useState({
      Type: "Razorpay",
      API_KEY: "rzp_test_UZ0V9jh3jMC0C9",
      SECRET_KEY: "XHctZxmnMhzkkwcAlDtF0Xuc",
    });
    const [isOnlinepay, setisOnlinepay] = useState(false);
    const [isPayatHotel, setisPayatHotel] = useState(false);
    const [isSemiPayment, setisSemiPayment] = useState(false);
    const [hotelwebsite, sethotelwebsite] = useState("");
    const [addTax, setaddTax] = useState(false);

    const [Subtotal,setSubtotal] = useState(0)
    const [Taxes,setTaxes] = useState(0)
    const [Grandtotal,setGrandtotal] = useState(0)

    const [Payment,setPayment] = useState("None")



  const fetchBookingEngineData = async()=>{
    
    const response = await fetch(`https://nexon.eazotel.com/booking/getenginedetails/${localStorage.getItem("hotelid")}/${localStorage.getItem("hid")}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, /",
        "Content-Type": "application/json",
      },
    });

    const json = await response.json();
    // console.log(json);

    setBookingEngineData(json.Details);
    setcurrency(json.Profile.currency);
    setisOnlinepay(json.Details.isOnlinePayment);
    //pay at hotel option
    setisPayatHotel(json.Details.isPayatHotel);
    //25% 
    setisSemiPayment(json.Details.isSemiPayment)
    //Gateway
    setGatewayConnected(json.Details.Gateway);
    //tax add
    setaddTax(json.Details.addTax);
    setHotelName(json.Details.HotelName);
  }

  const [details, setDetails] = useState("None");
  async function getId() {
    try {
      console.log("hello")
      const data = await fetch("/setId");
      const resp = await data.json();
      console.log(resp)

      if (resp.Status) {
        localStorage.setItem("hotelid", resp.ndid);
        localStorage.setItem("hid", resp.hId);
        localStorage.setItem("domain", resp.Domain);
        await fetchBookingEngineData();
        fetchWebsiteData(resp.Domain);
        fetchRooms();
      }

    } catch{ 
      console.log("Error aarhi h yaar")
    }
  }

  useEffect(() => {
  

    //Comment these lines if runnning with backend
    localStorage.setItem("hotelid", "0edb8b6c-2c64-44d1-b56a-9165a2242758");
    localStorage.setItem("hid", "50960024");
    localStorage.setItem("domain", "ahistays");
    setdomain("ahistays")
    fetchBookingEngineData();
    fetchWebsiteData("ahistays");
    fetchRooms();
    // till here

    //Comment these lines if runnning with frontend
    // getId();
    //till here
  }, []);
 

  //fetch booking engine data for ndid and hid





  return (
    <AuthContext.Provider
      value={{
        linktohit,
        websiteData,
        loading,
        setLoading,
        roomsData,
        setRoomsData,domain,


        //booking engine
        selectedDate, setSelectedDate,
        nextselectedDate,setnextselectedDate,
        Nights,setNights,
        minAdult,setminAdult,
        minkids,setminkids,
        Adult,setAdult,
        kids,setkids,
        BookingEngineRooms , setBookingEngineRooms,RateChange,setratesChange,Packages,setPackages,setAvailable,
        setMealPlan,Available,RoomNameAvailable,currency,RoomCategoryCombination,setRoomCategoryCombination,
        setDelux,setDeluxAdult,
        setSuperDelux,setSuperDeluxAdult,
        setSuite,setSuiteAdult,
        setPremium,setPremiumAdult,
        setPremiereRetreat,setPremiereRetreatAdult,
        setEliteSuite,setEliteSuiteAdult,
        setGrandDeluxe,setGrandDeluxeAdult,
        setImperialSuite,setImperialSuiteAdult,
        setSupremeRetreat,setSupremeRetreatAdult,
        setRoyalDeluxe,setRoyalDeluxeAdult,
        setPrestigeSuite,setPrestigeSuiteAdult,
        setExclusiveRetreat,setExclusiveRetreatAdult,maxAdult, setmaxAdult,

        Delux,
        SuperDelux,
        Suite,
        Premium,
        PremiereRetreat,
        EliteSuite,
        GrandDeluxe,
        ImperialSuite,
        SupremeRetreat,
        RoyalDeluxe,
        PrestigeSuite,
        ExclusiveRetreat,
        DeluxAdult,
        SuperDeluxAdult,
        SuiteAdult,
        PremiumAdult,
        PremiereRetreatAdult,
        EliteSuiteAdult,
        GrandDeluxeAdult,
        ImperialSuiteAdult,
        SupremeRetreatAdult,
        RoyalDeluxeAdult,
        PrestigeSuiteAdult,
        ExclusiveRetreatAdult,
        Subtotal,setSubtotal,
        Taxes,setTaxes,
        Grandtotal,setGrandtotal,GatewayConnected,
        setGatewayConnected,
        hotelwebsite,
        sethotelwebsite,
        addTax,
        setaddTax,isOnlinepay,
        isPayatHotel,
        isSemiPayment,HotelName,setHotelName,
        Payment,setPayment
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
